@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .App{
    @apply flex-row;
    @apply justify-center;
    @apply items-center;
    @apply text-center;
  }
}
